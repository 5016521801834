<template>
  <PageContentLayoutOnly headerColor="#fafafa">
    <div class="whitecolor">
      <div class="level pl-5 pr-5">
        <div class="pt-5 level-left level-item title">{{ $t('quickApprove.title') }}</div>
        <div class="level-right">
          <div class="level-item">
            <svg
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
          </div>
          <div class="level-item">
            <b-button label="Back to Accounts Payable" @click.prevent="returnToParent()" />
          </div>
        </div>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-12 pl-5 pr-5 pb-5">
          <div class="tile is-parent">
            <div class="tile  is-child notification headerColor" v-if="formData">
              <div class="box is-12">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <transition name="fade" mode="out-in" appear>
                    <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
                      <fieldset>
                        <div
                          class="font-bold"
                          v-if="
                            apInvoice &&
                              apInvoice != undefined &&
                              apInvoice.apInvoiceApproval &&
                              apInvoice.apInvoiceApproval != undefined &&
                              apInvoice.apInvoiceApproval.apInvoiceStatus &&
                              apInvoice.apInvoiceApproval.apInvoiceStatus != undefined
                          "
                        >
                          <span v-if="apInvoice.apInvoiceApproval.approvalDate && isPostedAlready">
                            {{
                              `Posted:  ${formatDateFn(apInvoice.apInvoiceApproval.approvalDate)}`
                            }}
                          </span>
                          <span v-else-if="apInvoice.apInvoiceApproval.approvalDate">
                            {{
                              `${
                                apInvoice.apInvoiceApproval.apInvoiceStatus.status
                              }:  ${formatDateFn(apInvoice.apInvoiceApproval.approvalDate)}`
                            }} </span
                          ><span v-else>
                            {{ apInvoice.apInvoiceApproval.apInvoiceStatus.status }}
                          </span>
                        </div>
                        <div v-else class="font-bold">No Status</div>
                      </fieldset>
                      <fieldset>
                        <div class="columns">
                          <div class="column is-6">
                            <valid-input
                              disabled="true"
                              style="width:50px !important;"
                              name="InvoiceNumber"
                              label="Invoice Number"
                              maxlength="50"
                              type="text"
                              vid="InvoiceNumber"
                              placeholder="Invoice Number"
                              spellcheck="true"
                              aria-label="Incoice Number"
                              rules="max:50|required"
                              class="is-small"
                              v-model="formData.invoiceNumber"
                            />
                          </div>
                          <div class="column is-6" style="padding-top: 30px;">
                            <b-button
                              type="button"
                              class="is-medium expanded is-light"
                              expanded
                              :disabled="loading"
                              @click.prevent="setToggleUpload()"
                            >
                              View Invoice
                            </b-button>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <valid-input
                              style="width:50px !important;"
                              aria-readonly="true"
                              aria-disabled="true"
                              disabled="true"
                              name="Invoice Total"
                              label="Invoice Total"
                              type="text"
                              vid="InvoiceTotal"
                              placeholder="Invoice Total"
                              spellcheck="true"
                              aria-label="Invoice Total"
                              rules="required"
                              class="is-small"
                              v-model="invoiceTotal"
                            />
                          </div>
                          <div class="columns column is-6" style="padding-top: 30px;">
                            <b-field class="column" v-if="payeeFilterQuery && apInvoice">
                              <b-button
                                type="button"
                                class="is-medium expanded is-light"
                                expanded
                                :disabled="loading"
                                @click.prevent="
                                  confirmQuickApprove(payeeFilterQuery, apInvoice.apInvoiceID)
                                "
                              >
                                Approve
                              </b-button>
                            </b-field>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6" aria-label="Select Payee">
                            <valid-input
                              style="width:50px !important;"
                              aria-readonly="true"
                              aria-disabled="true"
                              disabled="true"
                              name="PayeeFilterQuery"
                              label="Payee"
                              type="text"
                              vid="payeeFilterQuery"
                              placeholder="Payee"
                              spellcheck="true"
                              aria-label="Payee"
                              rules="required"
                              class="is-small"
                              v-model="payeeFilterQuery"
                            />
                          </div>
                          <div class="column is-6"></div>
                        </div>
                      </fieldset>
                      <fieldset>
                        <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                          <div class="field">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </fieldset>
                    </form>
                  </transition>
                </ValidationObserver>
              </div>
            </div>
          </div>
          <div class="is-12">
            <div class="is-12 pt-3">
              <b-button
                style="width:100% !important;"
                type="button"
                class="is-large expanded is-primary"
                expanded
                @click.prevent="toggleShowInvoiceCharges()"
                >{{
                  `(${
                    apLineItems && apLineItems.length > 0 ? apLineItems.length : 0
                  }) Invoice Charges`
                }}</b-button
              >
            </div>
            <div
              class="is-12"
              v-if="invoiceChargeToggle == true && apLineItems && apLineItems.length > 0"
            >
              <PageContentLayoutOnly>
                <ModernTable
                  :filters="filters"
                  :rows="apLineItems"
                  :columns="columns"
                  :pagination="pagination"
                >
                  <template v-slot:header>
                    <h3 class="" style="display: inline-block">
                      {{ $t('invoiceCharges.title') }}
                    </h3>
                    <div class="pb-5">
                      <b-button
                        type="is-ghost"
                        @click="toggleFilters"
                        :style="styles.filterButton"
                        :class="'filter-button ' + (filters.show ? 'active' : '')"
                      >
                        <b-icon
                          pack="mdi"
                          icon="filter-outline"
                          size="is-small"
                          style="display: inline-block"
                        >
                        </b-icon>
                        <div style="display: inline-block">Filter</div>
                      </b-button>
                    </div>
                  </template>
                </ModernTable>
              </PageContentLayoutOnly>
            </div>
            <div class="is-12 pt-3">
              <b-button
                style="width:100% !important;"
                type="button"
                class="is-large expanded is-primary"
                expanded
                @click.prevent="toggleShowApprovals()"
                >{{
                  `(${apApprovers && apApprovers.length > 0 ? apApprovers.length : 0}) Approvals`
                }}</b-button
              >
            </div>
            <div
              class="is-12"
              v-if="invoiceApprovalToggle == true && apApprovers && apApprovers.length > 0"
            >
              <PageContentLayoutOnly>
                <ModernTable
                  :filters="filters"
                  :rows="apApprovers"
                  :columns="approverColumns"
                  :pagination="pagination"
                >
                  <template v-slot:header>
                    <h3 style="display: inline-block">{{ $t('invoiceApprovals.title') }}</h3>

                    <b-button
                      type="is-ghost"
                      @click="toggleFilters"
                      :style="styles.filterButton"
                      :class="'filter-button ' + (filters.show ? 'active' : '')"
                    >
                      <b-icon
                        pack="mdi"
                        icon="filter-outline"
                        size="is-small"
                        style="display: inline-block"
                      >
                      </b-icon>
                      <div style="display: inline-block">Filter</div>
                    </b-button>
                  </template>
                </ModernTable>
              </PageContentLayoutOnly>
            </div>
            <div class="is-12 pt-3">
              <b-button
                style="width:100% !important;"
                type="button"
                class="is-large expanded is-primary"
                expanded
                @click.prevent="toggleShowNotes()"
                >{{ `(${apNotes && apNotes.length > 0 ? apNotes.length : 0}) Notes` }}</b-button
              >
            </div>
            <div class="is-12" v-if="invoiceNoteToggle == true && apNotes && apNotes.length > 0">
              <PageContentLayoutOnly>
                <ModernTable
                  :filters="filters"
                  :rows="apNotes"
                  :columns="noteColumns"
                  :pagination="pagination"
                >
                  <template v-slot:header>
                    <h3 style="display: inline-block">{{ $t('invoiceNotes.title') }}</h3>

                    <b-button
                      type="is-ghost"
                      @click="toggleFilters"
                      :style="styles.filterButton"
                      :class="'filter-button ' + (filters.show ? 'active' : '')"
                    >
                      <b-icon
                        pack="mdi"
                        icon="filter-outline"
                        size="is-small"
                        style="display: inline-block"
                      >
                      </b-icon>
                      <div style="display: inline-block">Filter</div>
                    </b-button>
                  </template>
                </ModernTable>
              </PageContentLayoutOnly>
            </div>
            <div class="is-12 pt-3">
              <b-button
                style="width:100% !important;"
                type="button"
                class="is-large expanded is-primary"
                expanded
                @click.prevent="toggleShowAttachments()"
                >{{
                  `(${
                    apAttachments && apAttachments.length > 0 ? apAttachments.length : 0
                  }) Attachments`
                }}</b-button
              >
            </div>
            <div
              class="is-12"
              v-if="invoiceAttachmentToggle == true && apAttachments && apAttachments.length > 0"
            >
              <PageContentLayoutOnly>
                <ModernTable
                  :filters="filters"
                  :rows="apAttachments"
                  :columns="attachmentColumns"
                  :pagination="pagination"
                >
                  <template v-slot:header>
                    <h3 style="display: inline-block">{{ $t('invoiceNotes.title') }}</h3>

                    <b-button
                      type="is-ghost"
                      @click="toggleFilters"
                      :style="styles.filterButton"
                      :class="'filter-button ' + (filters.show ? 'active' : '')"
                    >
                      <b-icon
                        pack="mdi"
                        icon="filter-outline"
                        size="is-small"
                        style="display: inline-block"
                      >
                      </b-icon>
                      <div style="display: inline-block">Filter</div>
                    </b-button>
                  </template>
                </ModernTable>
              </PageContentLayoutOnly>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :toggle.sync="toggle">
      <DocModal :apInvoiceID="parseInt(apInvoiceID)" :reloadPhotos="reloadPhotos"> </DocModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { mapState, mapGetters } from 'vuex'
import DocModal from '@/components/DocumentInvoiceModal'
import Modal from '@/components/Modal'
import ModernTable from '@/components/tables/Modern/Table'

import { data } from './keys/data'
import { methods } from './keys/methods'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'QuickApprove',
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly,
    DocModal,
    Modal,
    ModernTable
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    }),
    ...mapGetters('accounting', ['payeeDropDownList']),
    ...mapGetters('accounting', ['apInvoice']),
    ...mapGetters('accounting', ['apInvoiceStatus']),
    ...mapGetters('accounting', ['payeeDropDownList']),
    payeeFilterSuggestions() {
      if (this.payeeFilterQuery && this.payeeDropDownList && this.payeeDropDownList != undefined) {
        return this.payeeDropDownList.filter(
          a => a.label.indexOf(this.payeeFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.payeeDropDownList
      }
    },
    totalInvoiceCharges() {
      if (
        this.apInvoice &&
        this.apInvoice != undefined &&
        this.apInvoice.apInvoiceLineItems &&
        this.apInvoice.apInvoiceLineItems != undefined
      ) {
        var total = this.apInvoice.apInvoiceLineItems.reduce((acc, item) => acc + item.amount, 0)

        return this.formatter.format(total)
      } else {
        return 0
      }
    }
  },

  mounted() {
    this.loading = true
    if (this.$route.params.id) {
      this.apInvoiceID = this.$route.params.id
    }

    this.$store.dispatch('accounting/getPayeeList').then(() => {
      if (this.payeeDropDownList && this.payeeDropDownList != undefined) {
        if (this.isDebug == true) {
          console.debug('payeeDropDownList=' + JSON.stringify(this.payeeDropDownList))
        }

        this.data = this.payeeDropDownList
      }
    })

    if (this.apInvoiceID) {
      if (this.isDebug == true) console.debug('apInvoiceId=' + this.apInvoiceID)
      this.reload()
    }

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        quickApprove: { title: 'Approve Invoice' },
        invoiceCharges: { title: 'Charges' },
        invoiceNotes: { title: 'Notes' },
        invoiceAttachments: { title: 'Attachments' },
        invoiceApprovals: { title: 'Approvals' }
      }
    }
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
