/*
  import { accountsPayable } from '@/services/Invoices/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await accountsPayable.dispatch ('getAskQuestionList', {
      vendorID
    });
  */

  async getChargeLineItemList({}, { apInvoiceID }) {
    console.log('in getChargeLineItemList...')
    try {
      const result = await kms.get('/AccountsPayable/APInvoiceLineItem/List', {
        params: {
          apInvoiceID: apInvoiceID
        }
      })

      if (isDebug == true) console.debug('getChargeLineItemList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The invoice line itmes could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getNotesList({}, { apInvoiceID }) {
    console.log('in getNotesList...')
    try {
      const result = await kms.get('/AccountsPayable/APInvoiceNote/List', {
        params: {
          apInvoiceID: apInvoiceID
        }
      })

      if (isDebug == true) console.debug('getNotesList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The invoice notes could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getApInvoiceById({}, { payload = {} }) {
    try {
      if (
        payload &&
        payload != undefined &&
        payload.apInvoiceID &&
        payload.apInvoiceID != undefined
      ) {
        if (isDebug == true) console.debug('payload=' + JSON.stringify(payload))

        const invoice = await kms.get(`/AccountsPayable/APInvoice/${payload.apInvoiceID}`)

        if (isDebug == true) console.debug('getApInvoiceById=' + JSON.stringify(invoice))

        return {
          invoice: invoice
        }
      }
    } catch (e) {
      if (isDebug == true) console.debug('error in module getApInvoiceById' + e)
    }

    return {
      invoice: null
    }
  },

  async getAskQuestionList({}) {
    console.log('in getAskQuestionList...')

    return {
      list: []
    }
  },

  // Add Ask Question
  async addAskQuestion({ dispatch }, { done }) {
    if (isDebug == true) console.debug('...in addAskQuestion')
    try {
      const results = await kms.post(`/TODO/AskQuestion`, {})

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.askQuestionID > 0) {
        notifyMessage(`Successfully added a question.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding this question.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this question.`)
      console.error(exception)
    }
  },

  async updateCodingCompleteDate({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in updateCodingCompleteDate')
    try {
      const path =
        payload.codingCompleteDate != null
          ? `/AccountsPayable/APInvoice/UpdateCodingCompleteDate?apInvoiceID=${payload.apInvoiceID}&codingCompleteDate=${payload.codingCompleteDate}`
          : `/AccountsPayable/APInvoice/UpdateCodingCompleteDate?apInvoiceID=${payload.apInvoiceID}`
      const results = await kms.put(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apInvoiceID > 0) {
        notifyMessage(`Successfully completed invoice line-item coding.`)
        done({ details: results })
      } else {
        notifyError('There was a problem updating the coding complete date.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem updating the coding complete date.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const accountsPayable = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
