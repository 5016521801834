import { notifyMessage, notifyProblem, notifyWarning } from '@/services/notify'
import kms from '@/services/kms'
import { base64toBlob, showPdf } from '@/services/blobutility'
import Button from '@/components/buttons/Button'
import SimpleButton from '@/components/buttons/Simple'
import debounce from 'lodash/debounce'
import _get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { mapActions } from 'vuex'
import { notifyError } from '@/services/notify'
import ArrayFromTo from '@/utilities/Array/fromTo'

export const methods = {
  ...mapActions('accounting', ['getAccountsPayableApInvoiceList']),
  ...mapActions('accounting', ['getAccountsReceivableStatistics']),
  ...mapActions('accounting', ['getApInvoiceStatusList']),

  determineStatusRows() {
    if (this.apInvoiceStatusList && this.apInvoiceStatusList != undefined) {
      this.apStatuses = this.apInvoiceStatusList

      return this.apStatuses.map(i => {
        return {
          label: i.status,
          filter: i.status
        }
      })
    }
  },

  paginationChange: function(page) {
    this.currentPage = page
    let startIndex = (this.currentPage - 1) * this.perPage
    const lastIndex = this.currentPage * this.perPage - 1

    if (startIndex <= 0) {
      startIndex = 1
    }

    this.apInvoices = ArrayFromTo(this.originalApInvoices, startIndex, lastIndex)

    console.debug('paginationChange=' + this.currentPage + ', ' + startIndex + ', ' + lastIndex)
  },

  async downloadPdf(apInvoiceID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!apInvoiceID || apInvoiceID == undefined) {
      notifyProblem('There was a problem downloading this invoice.')
    }

    try {
      const params = {
        apInvoiceID: apInvoiceID,
        asBase64: true
      }

      const path = `/AccountsPayable/APInvoice/GetInvoiceFile`
      const returned = await kms.get(path, {
        params
      })

      if (this.isDebug == true)
        console.debug(
          'path base64 path=' +
            path +
            ' - params' +
            JSON.stringify(params) +
            ' - returned=' +
            JSON.stringify(returned)
        )
      if (returned && returned != undefined) {
        if (this.isDebug == true) console.debug('base64pdf........................' + returned)
        const contentType = 'application/pdf'
        const blob = base64toBlob(returned, contentType)
        showPdf(blob)
        notifyMessage(`Successfully downloaded the invoice.`)
        return
      }
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading this invoice.')
    }
  },

  determineInvoiceRows() {
    console.log('in determineInvoiceRows...' + JSON.stringify(this.accountsPayableApInvoiceList))
    if (this.accountsPayableApInvoiceList && this.accountsPayableApInvoiceList != undefined) {
      var dateFormat = 'MMMM yyyy'
      var dateFormatFull = 'LL'

      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      this.originalApInvoices = this.accountsPayableApInvoiceList.map(i => {
        var createdDateFormatted = _get(i, 'createdDate', '')
        var financialPeriosFormatted = _get(i, 'financialPeriod', '')

        var apInvoiceApprovalID = _get(i, 'apInvoiceApproval.apInvoiceApprovalID', 0)

        const status = _get(i, 'apInvoiceApproval.apInvoiceStatus.status', '')
        const completedDate = _get(i, 'codingCompletedDate', '')

        let approveTooltip =
          status != 'Pending' ? 'The status must be Pending in order to approve this invoice.' : ''
        approveTooltip =
          completedDate == null || completedDate == ''
            ? `${approveTooltip} Line item charges must be entered and marked as completed.`
            : approveTooltip

        if (this.isDebug == true) console.debug('completedDate=' + completedDate)

        let approvers = []
        approvers = _get(i, 'apInvoiceApproval.apInvoiceApprovalApprovers', null)

        try {
          createdDateFormatted = moment(createdDateFormatted).format(dateFormatFull)
          financialPeriosFormatted = moment(financialPeriosFormatted).format(dateFormat)
        } catch (exception) {
          console.error(exception)
        }

        var vendor = _get(i, 'payee.vendorName', '')
        var owner = _get(i, 'payee.ownerName', '')
        var payeeID = _get(i, 'payee.apPayeeID', 0)
        var payee = vendor

        var summedInvoiceTotal = 0
        if (i.apInvoiceLineItems) {
          summedInvoiceTotal = i.apInvoiceLineItems.reduce((acc, item) => acc + item.amount, 0)
        }

        if (vendor) {
          payee = vendor
          this.payeeLookup.push({ type: 'vendor', name: payee, payeeID: payeeID })
        }

        if (owner) {
          payee = owner
          this.payeeLookup.push({ type: 'owner', name: payee, payeeID: payeeID })
        }

        var apInvoiceId = _get(i, 'apInvoiceID', '')
        let hasApproveAuth = _get(i, 'hasApproveAuthentication', false)

        return {
          apInvoiceID: apInvoiceId,
          apInvoiceApprovalID: apInvoiceApprovalID,
          approvers: uniqBy(approvers, 'accountManagingUserName'),
          invoiceNumber: _get(i, 'invoiceNumber', ''),
          payee: payee,
          vendor,
          owner,
          payeeInformation: _get(i, 'payeeInformation', ''),
          createdDate: createdDateFormatted,
          financialPeriod: financialPeriosFormatted,
          status: status,
          approveTooltip: approveTooltip,
          invoiceTotal: formatter.format(summedInvoiceTotal),
          button: {
            component: SimpleButton,
            props: {
              to: {
                name: 'ap-invoice-detail',
                params: {
                  id: apInvoiceId
                }
              },
              text: 'details'
            }
          },
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processApproved(apInvoiceId)
              },
              text: 'Approve',
              visible: hasApproveAuth
            }
          }
        }
      })
    } else {
      console.log('else....')
    }

    this.current = 1
    this.total = this.originalApInvoices.length
    this.apInvoices = ArrayFromTo(this.originalApInvoices, 0, this.perPage - 1)
  },

  async filterByCreatedDateRange() {
    if (this.isDebug == true) console.debug('...in filterByCreatedDateRange')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    const from = this.$refs.fromCreatedDateComponent.getValue()
    const to = this.$refs.toCreatedDateComponent.getValue()

    if (!from || !from.year) {
      notifyError(`Please select valid created dates.`)
      loadingComponent.close()
      return
    }

    if (this.isDebug == true)
      console.debug('from=' + JSON.stringify(from) + ' - ' + JSON.stringify(to))

    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: 'createdDate',
        startCreatedDate: `${from.month}/${from.day}/${from.year}`,
        endCreatedDate: `${to.month}/${to.day}/${to.year}`
      })
      .then(() => {
        this.determineInvoiceRows()
        loadingComponent.close()
      })

    loadingComponent.close()
  },

  async filterByDateRange() {
    if (this.isDebug == true) console.debug('...in filterByDateRange')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    const from = this.$refs.fromDateComponent.getValue()
    const to = this.$refs.toDateComponent.getValue()

    if (!from || !from.year) {
      notifyError(`Please select valid billing dates.`)
      loadingComponent.close()
      return
    }

    if (this.isDebug == true)
      console.debug('from=' + JSON.stringify(from) + ' - ' + JSON.stringify(to))

    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: 'billingDate',
        startBillingDate: `${from.month}/${from.day}/${from.year}`,
        endBillingDate: `${to.month}/${to.day}/${to.year}`
      })
      .then(() => {
        this.determineInvoiceRows()
        loadingComponent.close()
      })

    loadingComponent.close()
  },

  async filterByPayee() {
    if (this.isDebug == true) console.debug('...in filterByPayee=' + this.payee)

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (!this.payee || this.payee == undefined || this.payee.length == 0) {
      notifyError('Please enter a vendor or payee.')
      loadingComponent.close()
      return
    }

    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: 'payee',
        filterPayeeName: this.payee
      })
      .then(() => {
        this.determineInvoiceRows()
        loadingComponent.close()
      })

    loadingComponent.close()
  },

  async filterByInvoiceNumber() {
    if (this.isDebug == true) console.debug('...in filterByInvoiceNumber=' + this.payee)

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (!this.invoiceNumber || this.invoiceNumber == undefined || this.invoiceNumber.length == 0) {
      notifyError('Please enter an invoice number.')
      loadingComponent.close()
      return
    }

    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: 'invoiceNumber',
        filterInvoiceNumber: this.invoiceNumber
      })
      .then(() => {
        this.determineInvoiceRows()
        loadingComponent.close()
      })

    loadingComponent.close()
  },

  async filterByAmount() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (
      !this.amountFrom ||
      this.amountFrom == undefined ||
      !this.amountTo ||
      this.amountTo == undefined
    ) {
      notifyError('Please enter valid amounts.')
      loadingComponent.close()
      return
    }

    if (this.isDebug == true) console.debug('...in filterByAmount')
    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: 'amount',
        amountFrom: this.amountFrom,
        amountTo: this.amountTo
      })
      .then(() => {
        this.determineInvoiceRows()
        loadingComponent.close()
      })

    loadingComponent.close()
  },

  async filterByGl() {
    if (this.isDebug == true) console.debug('...in filterByGl')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (
      this.glExpenseDropDownList &&
      this.glExpenseDropDownList.length > 0 &&
      this.glExpenseFilterQuery &&
      this.glExpenseFilterQuery.length > 0
    ) {
      const glExpense = this.glExpenseDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.glExpenseFilterQuery.toLowerCase())
      )

      const glExpenseId = glExpense[0] ? glExpense[0].value : 0

      if (glExpenseId <= 0) {
        notifyError('Please select a G/L Expense.')
        loadingComponent.close()
        return
      }

      await this.$store
        .dispatch('accounting/getAccountsPayableApInvoiceList', {
          filter: 'gl',
          glExpenseId: glExpenseId
        })
        .then(() => {
          this.determineInvoiceRows()
          loadingComponent.close()
        })
    }

    loadingComponent.close()
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reloadRequests() {
    console.debug('in reloadRequests...')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (this.radio && this.radio == 'query') {
      this.isQuery = true
      await this.loadGlExpenses()
      loadingComponent.close()

      return
    } else {
      this.isQuery = false
    }

    this.loading = true

    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: this.radio
      })
      .then(() => {
        this.determineInvoiceRows()
      })

    loadingComponent.close()

    this.loading = false
  },

  async reload() {
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (this.isDebug == true) console.debug('in reload...')

    await this.$store.dispatch('accounting/getApInvoiceStatusList').then(() => {
      this.determineStatusRows()
    })
    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: 'thirty'
      })
      .then(async () => {
        this.determineInvoiceRows()

        await this.loadGlExpenses()
      })

    loadingComponent.close()

    this.loading = false
  },

  async loadGlExpenses() {
    this.$store.dispatch('accounting/getGlExpenseList').then(() => {
      console.log('in getGlExpenseList....')
      if (this.glExpenseDropDownList && this.glExpenseDropDownList != undefined) {
        if (this.isDebug == true)
          console.debug(
            'glExpenseDropDownList=--------------------------------------------' +
              JSON.stringify(this.glExpenseDropDownList)
          )

        this.data = this.glExpenseDropDownList
      }
    })
  },

  redirectToDetails(apInvoiceID) {
    if (this.isDebug == true) console.debug('redirectToDetails=' + apInvoiceID)
    if (apInvoiceID > 0) {
      const detailsPath = '/ap-invoice-detail/' + apInvoiceID + '#tab'
      this.$router.push({
        path: detailsPath,
        params: { id: apInvoiceID }
      })
    }
  },

  redirectToAddNote(apInvoiceID) {
    if (apInvoiceID > 0) {
      const notesPath = '/invoicenotes/' + apInvoiceID + '?shownotes=true#tab'

      this.$router.push({
        path: notesPath,
        params: {
          id: apInvoiceID,
          shownotes: true
        }
      })
    }
  },

  redirectToSetApproved(apInvoiceID) {
    if (apInvoiceID > 0) {
      this.$router.push({
        name: 'ap-invoice-set-approved',
        params: {
          id: apInvoiceID,
          setapproved: true
        }
      })
    }
  },

  openAskQuestionModal(apInvoiceID, payee) {
    if (apInvoiceID > 0) {
      this.apInvoiceID = apInvoiceID
      this.apInvoiceLabel = `Add Payee - ${payee}`

      const selectedPayee = this.payeeLookup.filter(f => f.name === payee)
      if (this.isDebug === true) console.debug('selectedPayee=' + JSON.stringify(selectedPayee))
      this.apInvoicePayeeID =
        selectedPayee[0] && selectedPayee[0] != undefined ? selectedPayee[0].payeeID : 0

      if (this.isDebug === true)
        console.debug(
          'apInvoiceID=' +
            this.apInvoiceID +
            ', apInvoiceLabel=' +
            this.apInvoiceLabel +
            ', apInvoicePayeeID=' +
            this.apInvoicePayeeID
        )

      if (this.apInvoiceID > 0) {
        this.$router.push({
          name: 'newconversation',
          params: {
            apinvoiceid: this.apInvoiceID,
            apinvoicelabel: this.apInvoiceLabel,
            apinvoicepayeeid: this.apInvoicePayeeID
          }
        })
      }
    }
  },

  openDenyNoteModal(apInvoiceID) {
    if (apInvoiceID > 0) {
      this.apInvoiceID = apInvoiceID
      this.denyWithNoteToggle = true
    }
  },

  confirmSetDenied(apInvoice) {
    this.apInvoiceID = apInvoice.apInvoiceID
    this.apInvoice = apInvoice

    this.$buefy.dialog.confirm({
      title: 'Deny A/P Invoice',
      message: `Are you sure you want to <b>Deny Approval</b> for this invoice: ${apInvoice.invoiceNumber}?`,
      confirmText: 'Deny A/P Invoice',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.setToDenied()
    })
  },

  async setToDenied() {
    if (this.isDebug == true)
      console.debug('...in setToDenied and apInvoice=' + JSON.stringify(this.apInvoice))

    if (!this.apInvoiceID || this.apInvoiceID <= 0) {
      notifyError('There was a problem setting your invoice to denied.')
      return
    }

    try {
      const data = {
        apInvoiceID: this.apInvoice.apInvoiceID,
        statusCode: 'Denied'
      }

      const path = `/AccountsPayable/APInvoiceApproval/SetApprovalStatus?apInvoiceID=${this.apInvoice.apInvoiceID}&statusCode=Denied`

      this.loading = true

      const results = await kms.post(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.apInvoiceApprovalID > 0) {
        this.reload()
        this.isReadyForApproval = false
        notifyMessage(`The invoice was successfully set to denied status.`)
      }
    } catch (e) {
      this.loading = false
      notifyError(e)
    }
    this.loading = false
  },

  processApproved(apInvoiceID) {
    if (this.isDebug == true) console.debug('processApprove=' + apInvoiceID)

    this.$router.push({
      name: 'ap-invoice-quickapprove',
      params: { id: apInvoiceID }
    })
  },

  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (
      this.glExpenseDropDownList &&
      this.name &&
      this.glExpenseDropDownList.length > 0 &&
      this.name.length > 0
    ) {
      if (this.isDebug == true)
        console.debug('glExpenseDropDownList=' + JSON.stringify(this.glExpenseDropDownList))

      this.data = this.glExpenseDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    } else {
      this.data = []
    }

    this.isFetching = false
  }, 500),
  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250),

  createInvoiceModal() {
    if (this.isDebug == true) console.debug('in createInvoiceModal...')
    this.toggle = true
  }
}
