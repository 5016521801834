<template>
  <div deny-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Ask a Question</div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(addAskQuestion)">
          <fieldset>
            <div class="column is-12">
              <valid-input
                style="width:50px !important;"
                name="Note"
                label="Note"
                maxlength="8000"
                type="textarea"
                vid="Note"
                placeholder="Note"
                spellcheck="true"
                aria-label="Note"
                rules="max:8000|required"
                class="is-small"
                v-model="formData.note"
              />
            </div>
            <button type="submit" :disabled="loading" class="button is-primary is-rounded">
              {{ buttonText }}
            </button>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  props: {
    apInvoiceID: Number,
    apInvoice: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
