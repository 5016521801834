<template>
  <div id="invoice-modal">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div class="column is-12" aria-label="Select Payee">
              <label class="label">Select Payee</label>
              <b-autocomplete
                size="is-small"
                field="label"
                icon="magnify"
                :loading="isFetching"
                placeholder="e.g. Vendor Name or Owner Name"
                :data="data"
                :open-on-focus="openOnFocus"
                v-model="payeeFilterQuery"
                required="true"
                @typing="getAsyncData"
                @select="option => (selected = option)"
              >
              </b-autocomplete>
            </div>
            <div class="column is-12">
              <valid-input
                style="width:50px !important;"
                name="PayeeInformation"
                label="Payee Information"
                maxlength="100"
                type="text"
                vid="PayeeInformation"
                placeholder="Payee Information"
                spellcheck="true"
                aria-label="Payee Information"
                rules="max:100|required"
                class="is-small"
                v-model="formData.PayeeInformation"
              />
            </div>
            <div class="column is-12">
              <valid-input
                style="width:50px !important;"
                name="InvoiceNumber"
                label="Invoice Number"
                maxlength="50"
                type="text"
                vid="InvoiceNumber"
                placeholder="Invoice Number"
                spellcheck="true"
                aria-label="Incoice Number"
                rules="max:50|required"
                class="is-small"
                v-model="formData.InvoiceNumber"
              />
            </div>
            <div class="column is-12">
              <b-field label="Posting Date">
                <b-datepicker
                  v-model="formData.PostingDate"
                  label="Posting Date"
                  rules="required"
                  aria-required="Posting Date is required"
                  :show-week-number="showWeekNumber"
                  placeholder="Click to select Posting Date..."
                  icon="calendar-today"
                  trap-focus
                ></b-datepicker>
              </b-field>
            </div>
            <div class="column is-12">
              <b-field label="Financial Period">
                <b-datepicker
                  v-model="formData.FinancialPeriod"
                  label="Financial Period"
                  rules="required"
                  aria-required="Financial Period is required"
                  :show-week-number="showWeekNumber"
                  placeholder="Click to select Financial Period..."
                  icon="calendar-today"
                  trap-focus
                ></b-datepicker>
              </b-field>
            </div>
            <div class="columns pl-3 pt-3">
              <b-field class="column is-5" label="Billing Period Start Date">
                <b-datepicker
                  v-model="formData.StartDate"
                  label="Billing Period Start Date"
                  rules="required"
                  aria-required="Billing Period Start Date is required"
                  :show-week-number="showWeekNumber"
                  placeholder="Click to select Billing Period Start Date..."
                  icon="calendar-today"
                  trap-focus
                ></b-datepicker>
              </b-field>
              <div class="column is-1" style="padding-top: 40px !important;">TO</div>
              <b-field class="column is-5" label="Billing Period End Date">
                <b-datepicker
                  v-model="formData.EndDate"
                  label="Billing Period End Date"
                  rules="required"
                  aria-required="Billing Period End Date is required"
                  :show-week-number="showWeekNumber"
                  placeholder="Click to select Billing Period End Date..."
                  icon="calendar-today"
                  trap-focus
                ></b-datepicker>
              </b-field>
            </div>
            <div class="box is-8">
              <label class="label">Upload Invoice</label>
              <DocModal
                :apInvoiceID="newApInvoiceId"
                :closeFromParent="closeFromParent"
                :hideButtons="true"
                :submitUpload="submitUpload"
                :readyForSubmit="readyForSubmit"
                :autoClose="autoClose"
              >
              </DocModal>
            </div>
            <div class="pb-5" :style="{ float: 'right' }">
              <button
                type="submit"
                :disabled="!enableCreateButton"
                class="button is-primary is-rounded"
              >
                {{ buttonText }}
              </button>
            </div>
            <div class="pb-6"></div>
            <div class="pb-6"></div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import kms from '@/services/kms'
import { mapState, mapGetters } from 'vuex' //mapGetters
import DocModal from '@/components/DocumentInvoiceModal'
import $ from 'jquery'
import debounce from 'lodash/debounce'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'

import { notifyMessage, notifyError } from '@/services/notify'

let DEFAULT_FORM_VALUES = {
  payeeInformation: '',
  invoiceNumber: '',
  postingDate: null,
  financialPeriod: null,
  startDate: null,
  endDate: null
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'InvoiceModal',

  props: {
    ownerID: Number
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    DocModal
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: '',
      openOnFocus: true,
      payeeFilterQuery: null,
      isFetching: false,
      data: [],
      showWeekNumber: false,
      newApInvoiceId: 0,
      submitUpload: false,
      enableCreateButton: false,
      autoClose: true
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('accounting', ['payeeDropDownList']),
    payeeFilterSuggestions() {
      if (this.payeeFilterQuery && this.payeeDropDownList && this.payeeDropDownList != undefined) {
        return this.payeeDropDownList.filter(
          a => a.label.indexOf(this.payeeFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.payeeDropDownList
      }
    }
  },

  mounted() {
    this.loading = true
    this.$store.dispatch('accounting/getPayeeList').then(() => {
      if (this.payeeDropDownList && this.payeeDropDownList != undefined) {
        if (this.isDebug == true)
          console.debug('payeeDropDownList=' + JSON.stringify(this.payeeDropDownList))

        this.data = this.payeeDropDownList

        this.formData = { ...DEFAULT_FORM_VALUES }
        this.$refs.form.reset()

        this.buttonText = 'Create Invoice'
      }
    })

    this.loading = false
  },

  methods: {
    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payeeId = this.payeeDropDownList.filter(i => i.label.includes(this.payeeFilterQuery))

      const payload = {
        apPayeeID: payeeId[0] ? payeeId[0].value : 0,
        hoaID: this.hoaId,
        invoiceNumber:
          this.formData.InvoiceNumber && this.formData.InvoiceNumber != undefined
            ? this.formData.InvoiceNumber
            : null,
        payeeInformation:
          this.formData.PayeeInformation && this.formData.PayeeInformation != undefined
            ? this.formData.PayeeInformation
            : null,
        financialPeriod:
          this.formData.FinancialPeriod && this.formData.FinancialPeriod != undefined
            ? this.formData.FinancialPeriod
            : null,
        postingDate:
          this.formData.PostingDate && this.formData.PostingDate != undefined
            ? this.formData.PostingDate
            : null,
        startDate:
          this.formData.StartDate && this.formData.StartDate != undefined
            ? this.formData.StartDate
            : null,
        endDate:
          this.formData.EndDate && this.formData.EndDate != undefined ? this.formData.EndDate : null
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },
    closeFromParent() {
      this.closeModal()
    },
    readyForSubmit() {
      this.enableCreateButton = true
    },
    closeModal() {
      this.$emit('update:toggle', (this.toggle = !this.toggle))

      // auto-close modal
      $('#clickclose').click()

      //Redirect to detail page of new invoice
      if (this.newApInvoiceId > 0) {
        const detailsPath = '/ap-invoice-detail/' + this.newApInvoiceId + '?refreshed=true#tab'
        this.$router.push({
          path: detailsPath,
          params: { id: this.newApInvoiceId }
        })
      }
    },
    async onFormSubmit() {
      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem submitting your new invoice.')
        return
      }

      try {
        const path = `/AccountsPayable/APInvoice`

        const payload = this.getPayload()
        let validationMessages = ''

        if (payload.apPayeeID == 0) {
          validationMessages = '<div>Please select a valid Payee.</div>'
        }

        if (payload.financialPeriod == null) {
          validationMessages += '<div>Please select a financial period.</div>'
        }

        if (payload.postingDate == null) {
          validationMessages += '<div>Please select a posting date.</div>'
        }

        if (payload.startDate == null) {
          validationMessages += '<div>Please select a billing period start date.</div>'
        }

        if (payload.endDate == null) {
          validationMessages += '<div>Please select a billing period end date.</div>'
        }

        if (validationMessages != '') {
          notifyError(validationMessages)
          return
        }

        this.loading = true
        let results = await kms.post(path, payload)

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results.apInvoiceID >= 0) {
          this.newApInvoiceId = results.apInvoiceID
          this.submitUpload = true

          notifyMessage(`The invoice was successfully created.`)
        } else {
          notifyError('There was a problem creating the invoice.')
        }
      } catch (e) {
        this.submitUpload = false

        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem creating the invoice.' + e)
      }
      this.loading = false
    },

    getAsyncData: debounce(function(name) {
      // String update
      if (this.name !== name) {
        this.name = name
        this.data = []
      }

      // String cleared
      if (!name.length) {
        this.data = []
        return
      }

      this.isFetching = true
      if (this.isDebug == true) console.debug('this.name=' + this.name)

      if (this.payeeDropDownList) {
        this.data = this.payeeDropDownList.filter(i =>
          i.label.toLowerCase().includes(this.name.toLowerCase())
        )
      }
      this.isFetching = false
    }, 500),
    getMoreAsyncData: debounce(function() {
      this.getAsyncData(this.name)
    }, 250)
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/_variables';

#invoice-modal {
  .step-content {
    @media screen and (max-width: $tablet) {
      padding: 0;
    }
  }

  .step-details {
    background-color: #fff !important;
    padding-left: 0.25rem !important;
    padding-right: 0.5rem !important;
  }

  .b-steps.is-small .steps {
    margin-top: 1rem;
  }

  .b-steps .steps + .step-content {
    padding-top: 0 !important;
  }

  .step-navigation {
    display: flex;
    justify-content: flex-end;
  }

  .my-grid-class {
    height: 400px;
  }

  tbody {
    min-height: 100px !important;
    overflow: auto !important;
  }
}
</style>
