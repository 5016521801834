import debounce from 'lodash/debounce'
import { ValidationError } from '@/services/errors'
import { mapActions } from 'vuex'
import kms from '@/services/kms'
import { notifyError, notifyMessage } from '@/services/notify'
import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'

var DEFAULT_FORM_VALUES = {
  apInvoiceID: 0,
  hoa: '',
  payeeInformation: '',
  invoiceNumber: '',
  invoiceDocument: '',
  postingDate: null,
  financialPeriod: null,
  startDate: null,
  endDate: null,
  codingCompletedDate: null
}

export const methods = {
  ...mapActions('user', ['selectHoaId']),

  generateCommitteeLists() {
    this.activeTable.rows = this.determineCommitteeRows({
      active: true
    })
    this.activeTable.loading = false

    this.inactiveTable.rows = this.determineCommitteeRows({
      active: false
    })
    this.inactiveTable.loading = false
  },

  generateLineItemLists() {
    this.activeTable.rows = this.determineLineItemRows({
      active: true
    })
    this.activeTable.loading = false

    this.inactiveTable.rows = this.determineLineItemRows({
      active: false
    })
    this.inactiveTable.loading = false
  },

  confirmQuickApprove(payeeName, id) {
    this.$buefy.dialog.confirm({
      title: 'Approve A/P Invoice',
      message: `Are you sure you want to <b>Approve</b> the invoice for: "${payeeName}"?`,
      confirmText: 'Approve A/P Invoice',
      type: 'is-info',
      hasIcon: true,
      onConfirm: () => this.setToApproved(id)
    })
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  toggleShowInvoiceCharges() {
    this.invoiceChargeToggle = !this.invoiceChargeToggle
  },

  toggleShowApprovals() {
    this.invoiceApprovalToggle = !this.invoiceApprovalToggle
  },

  toggleShowNotes() {
    this.invoiceNoteToggle = !this.invoiceNoteToggle
  },

  toggleShowAttachments() {
    this.invoiceAttachmentToggle = !this.invoiceAttachmentToggle
  },

  determineChargeRows() {
    if (this.apInvoice && this.apInvoice.apInvoiceLineItems) {
      var dateFormat = 'LL'

      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      this.apLineItems = this.apInvoice.apInvoiceLineItems.map(i => {
        const tempDate = _get(i, 'createdDate', '')
        const createdDate = parseDate(tempDate, dateFormat)

        var accountNumber = _get(i, 'glExpenseAccount.accountNumber', '')
        var subAccountNumber = _get(i, 'glExpenseAccount.subAccountNumber', '')
        var glExpenseAccountFull =
          accountNumber +
          '-' +
          subAccountNumber.toString().padStart(3, '0') +
          ' - ' +
          _get(i, 'glExpenseAccount.description', '')

        console.log('in aplineitems.......')

        return {
          amount: formatter.format(_get(i, 'amount', 0)),
          glExpenseAccount: glExpenseAccountFull,
          description: _get(i, 'description', ''),
          createdDate: createdDate
        }
      })
    } else {
      console.log('determineChargeRows else.............')
    }
  },

  determineApproverRows() {
    const dateFormat = 'LL'

    if (this.apInvoice && this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers) {
      this.apApprovers = this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers.map(entry => {
        const approver = _get(entry, 'accountManagingUserName', '')
        const isRequired = _get(entry, 'isApprovalRequired', false)
        const tempDate = _get(entry, 'createdDate', '')
        const status = _get(this.apInvoice.apInvoiceApproval.apInvoiceStatus, 'status', '')
        const createdDate = parseDate(tempDate, dateFormat)

        let isAuthenticated = _get(entry, 'isAuthenticated', false)
        if (isAuthenticated == null) {
          isAuthenticated = false
        }

        return {
          approver: approver,
          required: isRequired,
          status: status,
          createdDate: createdDate
        }
      })
    }
  },

  determineNoteRows() {
    const dateFormat = 'LL'

    if (this.apInvoice && this.apInvoice.apInvoiceNotes) {
      this.apNotes = this.apInvoice.apInvoiceNotes.map(entry => {
        const tempDate = _get(entry, 'createdDate', '')
        const note = _get(entry, 'note', '')
        const createdDate = parseDate(tempDate, dateFormat)
        const aspNetName = _get(entry, 'aspNetName', '')

        return {
          author: aspNetName,
          note: note,
          createdDate: createdDate
        }
      })
    }
  },

  determineAttachmentRows() {
    const dateFormat = 'LL'

    if (this.apInvoice && this.apInvoice.apInvoiceAttachments) {
      this.apAttachments = this.apInvoice.apInvoiceAttachments.map(entry => {
        const tempDate = _get(entry, 'createdDate', '')
        const documentId = _get(entry, 'documentID', '')
        const name = _get(entry, 'name', '')
        const createdDate = parseDate(tempDate, dateFormat)

        return {
          documentId: documentId,
          name: name,
          createdDate: createdDate
        }
      })
    }
  },

  async reload() {
    if (this.isDebug == true) console.debug('...in reload()')
    this.loading = true

    this.formData = { ...DEFAULT_FORM_VALUES }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    try {
      this.$store
        .dispatch('accounting/getApInvoiceById', {
          apInvoiceID: this.apInvoiceID,
          clientAspNetUserId: this.authUser != null ? this.authUser.aspNetUserID : null
        })
        .then(() => {
          if (this.apInvoice) {
            this.formData.apInvoiceID = this.apInvoiceID
            this.formData.hoa = this.apInvoice.hoaName
            this.formData.payeeInformation = this.apInvoice.payeeInformation
            this.formData.invoiceNumber = this.apInvoice.invoiceNumber
            this.formData.invoiceDocument = this.apInvoice.invoiceDocument
              ? this.apInvoice.invoiceDocument.filename
              : ''
            this.formData.postingDate = new Date(this.apInvoice.postingDate)
            this.formData.startDate = new Date(this.apInvoice.startDate)
            this.formData.endDate = new Date(this.apInvoice.endDate)
            this.formData.codingCompletedDate =
              this.apInvoice.codingCompletedDate != null
                ? new Date(this.apInvoice.codingCompletedDate)
                : null

            this.payeeFilterQuery = this.apInvoice.payee.ownerName
              ? this.apInvoice.payee.ownerName
              : `${this.apInvoice.payee.vendorName ? this.apInvoice.payee.vendorName : ''}`

            if (this.isDebug == true) console.debug('formData=' + JSON.stringify(this.formData))

            this.processInvoiceStatus()

            this.determineApproverRows()

            this.determineNoteRows()

            this.determineAttachmentRows()

            this.determineChargeRows()

            this.invoiceTotal = this.totalInvoiceCharges
          }
        })
    } catch (e) {
      console.debug('error in method for invoice:' + e)
    }

    this.loading = false
  },

  processInvoiceStatus() {
    //is this an approval invoice
    if (this.apInvoice && this.apInvoice != undefined) {
      if (this.isDebug == true)
        console.debug('processInvoiceStatus apInvoice=' + JSON.stringify(this.apInvoice))
      if (
        this.apInvoice.codingCompletedDate != null &&
        (this.apInvoice.apInvoiceApproval.apInvoiceStatus.codeHandleName == 'Pending' ||
          this.apInvoice.apInvoiceApproval.apInvoiceStatus.codeHandleName == 'Denied')
      ) {
        if (
          this.apInvoice.apInvoiceApproval &&
          this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers &&
          this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers.length > 0
        ) {
          if (this.isDebug == true) console.debug('...has invoice approval approvers')
          const requiredApproversHaveNotApproved = this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers.filter(
            f => f.isApprovalRequired == true && f.approvalDate == null
          )
          if (requiredApproversHaveNotApproved && requiredApproversHaveNotApproved.length > 0) {
            this.isReadyForApproval = false
            if (this.isDebug == true)
              console.debug(
                'requiredApproversHaveNotApproved=' +
                  JSON.stringify(requiredApproversHaveNotApproved)
              )
          } else {
            this.isReadyForApproval = true
          }
        } else {
          this.isReadyForApproval = true
        }
      }

      if (
        this.apInvoice.codingCompletedDate != null &&
        this.apInvoice.apInvoiceApproval.approvalDate != null &&
        this.apInvoice.apInvoiceApproval.apInvoiceStatus.codeHandleName == 'Approved' &&
        this.apInvoice.apInvoiceLineItems &&
        this.apInvoice.apInvoiceLineItems.length > 0
      ) {
        if (
          !this.apInvoice.apBills ||
          (this.apInvoice.apBills && this.apInvoice.apBills.length == 0)
        ) {
          this.isReadyForPost = true
        }
      }

      if (
        this.apInvoice.codingCompletedDate != null &&
        this.apInvoice.apInvoiceApproval.approvalDate != null &&
        this.apInvoice.apInvoiceApproval.apInvoiceStatus.codeHandleName == 'Approved' &&
        this.apInvoice.apInvoiceLineItems &&
        this.apInvoice.apInvoiceLineItems.length > 0
      ) {
        if (this.apInvoice.apBills && this.apInvoice.apBills.length > 0) this.isPostedAlready = true
      }
    }
  },

  returnToParent() {
    this.$router.push({
      name: 'accountspayable'
    })
  },

  setToggleUpload() {
    this.toggle = true
  },

  getPayload() {
    if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

    const payeeId = this.payeeDropDownList.filter(i => i.label.includes(this.payeeFilterQuery))

    const payload = {
      apInvoiceID: this.apInvoiceID,
      apPayeeID: payeeId[0] ? payeeId[0].value : 0,
      hoaID: this.hoaId,
      invoiceNumber:
        this.formData.invoiceNumber && this.formData.invoiceNumber != undefined
          ? this.formData.invoiceNumber
          : null,
      invoiceDocumentID:
        this.apInvoice && this.apInvoice.invoiceDocumentID
          ? this.apInvoice.invoiceDocumentID
          : null,
      payeeInformation:
        this.formData.payeeInformation && this.formData.payeeInformation != undefined
          ? this.formData.payeeInformation
          : null,
      financialPeriod: null, //finDateProcessed && finDateProcessed != undefined ? finDateProcessed : null,
      postingDate:
        this.formData.postingDate && this.formData.postingDate != undefined
          ? this.formData.postingDate
          : null,
      startDate:
        this.formData.startDate && this.formData.startDate != undefined
          ? this.formData.startDate
          : null,
      endDate:
        this.formData.endDate && this.formData.endDate != undefined ? this.formData.endDate : null,
      codingCompletedDate:
        this.formData.codingCompletedDate && this.formData.codingCompletedDate != undefined
          ? this.formData.codingCompletedDate
          : null
    }

    return payload
  },
  async onFormSubmit() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem submitting your new invoice.')
      return
    }

    try {
      const path = `/AccountsPayable/APInvoice`

      const payload = this.getPayload()
      let validationMessages = ''

      if (!payload.apPayeeID || payload.apPayeeID == 0) {
        validationMessages = '<div>Please select a valid Payee.</div>'
      }

      if (payload.financialPeriod == null) {
        validationMessages += '<div>Please select a financial period.</div>'
      }

      if (payload.postingDate == null) {
        validationMessages += '<div>Please select a posting date.</div>'
      }

      if (payload.startDate == null) {
        validationMessages += '<div>Please select a billing period start date.</div>'
      }

      if (payload.endDate == null) {
        validationMessages += '<div>Please select a billing period end date.</div>'
      }

      if (validationMessages != '') {
        notifyError(validationMessages)
        return
      }

      this.loading = true
      let results = await kms.put(path, payload)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.apInvoiceID >= 0) {
        this.reload()
        notifyMessage(`The invoice was successfully updated.`)
      }
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError('There was a problem saving the invoice.' + e)
    }
    this.loading = false
  },

  async setToApproved() {
    if (this.isDebug == true) console.debug('...in setToApproved')

    if (
      !this.formData ||
      this.formData == undefined ||
      !this.apInvoiceID ||
      this.apInvoiceID <= 0 ||
      !this.apInvoice.apInvoiceApproval ||
      this.apInvoice.apInvoiceApproval == undefined ||
      !this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers ||
      this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers == undefined
    ) {
      notifyError('There was a problem setting your invoice to approved.')
      return
    }

    try {
      this.$store
        .dispatch('accounting/getApprovalApInvoiceStatusByCodeName', {
          codeName: 'Approved'
        })
        .then(async () => {
          try {
            if (this.apInvoiceStatus && this.apInvoiceStatus != undefined) {
              if (this.isDebug == true)
                console.debug('this.apInvoiceStatus=' + JSON.stringify(this.apInvoiceStatus))

              if (this.isDebug == true)
                console.debug(
                  'approvers=' +
                    JSON.stringify(this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers)
                )

              var approver = this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers.filter(
                f => f.accountManagingUserName == this.authUser.name
              )

              if (this.isDebug == true) console.debug('approver=' + JSON.stringify(approver))

              const payload = {
                apInvoiceID: this.apInvoiceID
              }

              if (this.isDebug == true) console.debug('aproverPayload' + JSON.stringify(payload))

              if (approver && approver != undefined) {
                this.$store.dispatch('accounting/approveInvoiceApproval', {
                  payload: payload,
                  done: ({ details }) => {
                    if (details) {
                      notifyMessage(details.approvalMessage)
                    }
                  }
                })

                setTimeout(() => {
                  this.redirectToPosted()
                }, 1000)
              }
            }
          } catch (e) {
            this.loading = false
            notifyError(e)
          }
        })
    } catch (e) {
      this.loading = false
      if (e instanceof ValidationError) {
        notifyError(e)
      }

      notifyError(e)
    }
    this.loading = false
  },

  redirectToPosted() {
    this.$router.push({ name: 'ap-invoicespendingapproval', params: { filtered: 'approval' } })
  },

  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.payeeDropDownList) {
      this.data = this.payeeDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250)
}
