import moment from 'moment'

var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  formComplete: false,
  formData: {},
  loading: true,
  isDebug: true,
  toggle: false,
  invoiceApprovalToggle: false,
  invoiceChargeToggle: false,
  invoiceNoteToggle: false,
  invoiceAttachmentToggle: false,
  financialPeriodDate: null,
  reloadPhotos: true,
  activeTab: 'lineitems',
  openOnFocus: true,
  payeeFilterQuery: null,
  isFetching: false,
  invoiceTotal: 0,
  data: [],
  apApprovers: [],
  apNotes: [],
  apLineItems: [],
  apAttachments: [],
  showWeekNumber: false,
  apInvoiceID: 0,
  apInvoiceAttachmentID: 0,
  isReadyForApproval: false,
  isReadyForPost: false,
  isPostedAlready: false,
  selectedApInvoiceCharge: {},
  selectedApInvoiceNote: {},
  selectedApInvoiceAttachment: {},
  formatter: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }),
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  },
  filters: {
    show: false
  },

  pagination: {
    perPage: 10
  },

  columns: [
    {
      field: 'amount',
      label: 'Amount',
      aria: 'Amount',
      width: '5%',
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'glExpenseAccount',
      label: 'G/L Expense',
      width: '45%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      width: '45%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      date: true,
      width: '5%',
      sortable: true,
      searchable: true
    }
  ],
  approverColumns: [
    {
      field: 'approver',
      label: 'Approver',
      aria: 'Approver',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'required',
      label: 'Required',
      aria: 'Required',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'status',
      label: 'Status',
      aria: 'Status',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      date: true,
      width: '20%',
      sortable: true,
      searchable: true
    }
  ],
  noteColumns: [
    {
      field: 'author',
      label: 'Author',
      aria: 'Author',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'note',
      label: 'Note',
      aria: 'Note',
      width: '75%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      date: true,
      width: '5%',
      sortable: true,
      searchable: true
    }
  ],
  attachmentColumns: [
    {
      field: 'documentId',
      label: 'Document Id',
      aria: 'Document Id',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'documentName',
      label: 'Document Name',
      aria: 'Document Name',
      width: '60%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      date: true,
      width: '20%',
      sortable: true,
      searchable: true
    }
  ],
  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    }),
    topButtonPad: {
      paddingTop: '50px'
    }
  }
})
