import debounce from 'lodash/debounce'
import { ValidationError } from '@/services/errors'
import { mapActions } from 'vuex'
import kms from '@/services/kms'
import { notifyError, notifyMessage } from '@/services/notify'
import moment from 'moment'

var DEFAULT_FORM_VALUES = {
  apInvoiceID: 0,
  hoa: '',
  payeeInformation: '',
  invoiceNumber: '',
  invoiceDocument: '',
  postingDate: null,
  financialPeriod: null,
  startDate: null,
  endDate: null,
  codingCompletedDate: null
}

export const methods = {
  ...mapActions('user', ['selectHoaId']),

  generateCommitteeLists() {
    this.activeTable.rows = this.determineCommitteeRows({
      active: true
    })
    this.activeTable.loading = false

    this.inactiveTable.rows = this.determineCommitteeRows({
      active: false
    })
    this.inactiveTable.loading = false
  },

  generateLineItemLists() {
    this.activeTable.rows = this.determineLineItemRows({
      active: true
    })
    this.activeTable.loading = false

    this.inactiveTable.rows = this.determineLineItemRows({
      active: false
    })
    this.inactiveTable.loading = false
  },

  confirmDeleteInvoiceApprover(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting A/P Invoice Approver',
      message: `Are you sure you want to <b>delete</b> the invoice approver for ${name}?`,
      confirmText: 'Delete A/P Invoice Approver',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteInvoiceApprover(id)
    })
  },

  confirmDeleteInvoice(invoiceNumber) {
    this.$buefy.dialog.confirm({
      title: 'Deleting A/P Invoice',
      message: `Are you sure you want to <b>delete</b> the invoice for ${invoiceNumber}?`,
      confirmText: 'Delete A/P Invoice',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteInvoice()
    })
  },

  confirmSetApproved(invoiceNumber) {
    this.$buefy.dialog.confirm({
      title: 'Approve A/P Invoice',
      message: `Are you sure you want to <b>Approve</b> the invoice for: ${invoiceNumber}?`,
      confirmText: 'Approve A/P Invoice',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.setToApproved()
    })
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  confirmSetRemoveApproved(invoiceNumber) {
    this.$buefy.dialog.confirm({
      title: 'Remove A/P Invoice Approval',
      message: `Are you sure you want to <b>Remove the Approval</b> this invoice: ${invoiceNumber}?`,
      confirmText: 'Remove A/P Invoice Approval',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.setToRemoveStatus('Approved')
    })
  },

  confirmSetRemoveDenied(invoiceNumber) {
    this.$buefy.dialog.confirm({
      title: 'Remove A/P Invoice Denied',
      message: `Are you sure you want to <b>Remove the Denied Status</b> of this invoice: ${invoiceNumber}?`,
      confirmText: 'Remove A/P Invoice Denied',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.setToRemoveStatus('Denied')
    })
  },

  confirmPostToBill(invoiceNumber) {
    this.$buefy.dialog.confirm({
      title: 'Post A/P Invoice',
      message: `Are you sure you want to <b>Post</b> this invoice: ${invoiceNumber}?`,
      confirmText: 'A/P Invoice Post',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.setToPosted()
    })
  },

  async reload() {
    this.loading = true

    this.$forceUpdate()

    this.formData = { ...DEFAULT_FORM_VALUES }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    try {
      this.$store
        .dispatch('accounting/getApInvoiceById', {
          apInvoiceID: this.apInvoiceID,
          clientAspNetUserId: this.authUser != null ? this.authUser.aspNetUserID : null
        })
        .then(() => {
          if (this.apInvoice) {
            var finDate = this.apInvoice.financialPeriod
            this.$refs.financialDateComponent.month = moment(finDate).format('MM')
            this.$refs.financialDateComponent.year = moment(finDate).format('YYYY')

            this.formData.createdDate = moment(new Date(this.apInvoice.createdDate)).format(
              'MM/DD/YYYY'
            )
            this.formData.apInvoiceID = this.apInvoiceID
            this.formData.hoa = this.apInvoice.hoaName
            this.formData.payeeInformation = this.apInvoice.payeeInformation
            this.formData.invoiceNumber = this.apInvoice.invoiceNumber
            this.formData.invoiceDocument = this.apInvoice.invoiceDocument
              ? this.apInvoice.invoiceDocument.filename
              : ''
            this.formData.postingDate = new Date(this.apInvoice.postingDate)
            this.formData.startDate = new Date(this.apInvoice.startDate)
            this.formData.endDate = new Date(this.apInvoice.endDate)
            this.formData.codingCompletedDate =
              this.apInvoice.codingCompletedDate != null
                ? moment(new Date(this.apInvoice.codingCompletedDate)).format('MM/DD/YYYY')
                : null

            this.payeeFilterQuery = this.apInvoice.payee.ownerName
              ? this.apInvoice.payee.ownerName
              : `${this.apInvoice.payee.vendorName ? this.apInvoice.payee.vendorName : ''}`

            this.isVendor = this.apInvoice.payee.ownerName
              ? false
              : `${this.apInvoice.payee.vendorName ? true : false}`

            if (this.isDebug == true) console.debug('formData=' + JSON.stringify(this.formData))

            this.processInvoiceStatus()
          }
        })
    } catch (e) {
      console.debug('error in method for invoice:' + e)
    }

    this.loading = false
  },

  processInvoiceStatus() {
    //is this an approval invoice
    if (this.apInvoice && this.apInvoice != undefined) {
      if (this.isDebug == true)
        console.debug('processInvoiceStatus apInvoice=' + JSON.stringify(this.apInvoice))

      if (this.apInvoice.apInvoiceApproval.apInvoiceStatus.codeHandleName == 'Denied') {
        this.isDeniedStatus = true
      } else {
        this.isDeniedStatus = false
      }

      if (
        this.apInvoice.codingCompletedDate != null &&
        (this.apInvoice.apInvoiceApproval.apInvoiceStatus.codeHandleName == 'Pending' ||
          this.apInvoice.apInvoiceApproval.apInvoiceStatus.codeHandleName == 'Denied')
      ) {
        if (
          this.apInvoice.apInvoiceApproval &&
          this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers &&
          this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers.length > 0
        ) {
          if (this.isDebug == true) console.debug('...has invoice approval approvers')
          const requiredApproversHaveNotApproved = this.apInvoice.apInvoiceApproval.apInvoiceApprovalApprovers.filter(
            f => f.isApprovalRequired == true && f.approvalDate == null
          )
          if (requiredApproversHaveNotApproved && requiredApproversHaveNotApproved.length > 0) {
            this.isReadyForApproval = false
            if (this.isDebug == true)
              console.debug(
                'requiredApproversHaveNotApproved=' +
                  JSON.stringify(requiredApproversHaveNotApproved)
              )
          } else {
            this.isReadyForApproval = true
          }
        } else {
          this.isReadyForApproval = true
        }
      }

      if (
        this.apInvoice.codingCompletedDate != null &&
        this.apInvoice.apInvoiceApproval.approvalDate != null &&
        this.apInvoice.apInvoiceApproval.apInvoiceStatus.codeHandleName == 'Approved' &&
        this.apInvoice.apInvoiceLineItems &&
        this.apInvoice.apInvoiceLineItems.length > 0
      ) {
        if (
          !this.apInvoice.apBills ||
          (this.apInvoice.apBills && this.apInvoice.apBills.length == 0)
        ) {
          this.isReadyForPost = true
        }
      }

      if (
        this.apInvoice.codingCompletedDate != null &&
        this.apInvoice.apInvoiceApproval.approvalDate != null &&
        this.apInvoice.apInvoiceApproval.apInvoiceStatus.codeHandleName == 'Approved' &&
        this.apInvoice.apInvoiceLineItems &&
        this.apInvoice.apInvoiceLineItems.length > 0
      ) {
        if (this.apInvoice.apBills && this.apInvoice.apBills.length > 0) this.isPostedAlready = true
      }
    }
  },

  returnToParent() {
    this.$router.push({
      path: '/ap-invoicespendingapproval/approval'
    })
  },

  setToggleUpload() {
    this.toggle = true
  },

  getPayload() {
    if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

    const payeeId = this.payeeDropDownList.filter(i => i.label.includes(this.payeeFilterQuery))

    const finDate = this.$refs.financialDateComponent.getValue()
    let finDateProcessed = null
    if (finDate) {
      finDateProcessed = new Date(`${finDate.month}/01/${finDate.year}`)
      if (this.isDebug == true) console.debug('finDateProcessed=' + finDateProcessed)
    }

    const payload = {
      apInvoiceID: this.apInvoiceID,
      apPayeeID: payeeId[0] ? payeeId[0].value : 0,
      hoaID: this.hoaId,
      invoiceNumber:
        this.formData.invoiceNumber && this.formData.invoiceNumber != undefined
          ? this.formData.invoiceNumber
          : null,
      invoiceDocumentID:
        this.apInvoice && this.apInvoice.invoiceDocumentID
          ? this.apInvoice.invoiceDocumentID
          : null,
      payeeInformation:
        this.formData.payeeInformation && this.formData.payeeInformation != undefined
          ? this.formData.payeeInformation
          : null,
      financialPeriod: finDateProcessed && finDateProcessed != undefined ? finDateProcessed : null,
      postingDate:
        this.formData.postingDate && this.formData.postingDate != undefined
          ? this.formData.postingDate
          : null,
      startDate:
        this.formData.startDate && this.formData.startDate != undefined
          ? this.formData.startDate
          : null,
      endDate:
        this.formData.endDate && this.formData.endDate != undefined ? this.formData.endDate : null,
      codingCompletedDate:
        this.formData.codingCompletedDate && this.formData.codingCompletedDate != undefined
          ? moment(this.formData.codingCompletedDate, 'YYYY/MM/DD HH:mm:ss')
          : null
    }

    return payload
  },
  async onFormSubmit() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem submitting your new invoice.')
      return
    }

    try {
      const path = `/AccountsPayable/APInvoice`

      const payload = this.getPayload()
      let validationMessages = ''

      if (!payload.apPayeeID || payload.apPayeeID == 0) {
        validationMessages = '<div>Please select a valid Payee.</div>'
      }

      if (payload.financialPeriod == null) {
        validationMessages += '<div>Please select a financial period.</div>'
      }

      if (payload.postingDate == null) {
        validationMessages += '<div>Please select a posting date.</div>'
      }

      if (payload.startDate == null) {
        validationMessages += '<div>Please select a billing period start date.</div>'
      }

      if (payload.endDate == null) {
        validationMessages += '<div>Please select a billing period end date.</div>'
      }

      if (validationMessages != '') {
        notifyError(validationMessages)
        return
      }

      this.loading = true
      let results = await kms.put(path, payload)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.apInvoiceID >= 0) {
        this.reRender()
        notifyMessage(`The invoice was successfully updated.`)
      }
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError('There was a problem saving the invoice.' + e)
    }
    this.loading = false
  },

  reRender() {
    //Necessary for now to force other tabs to re-render
    this.$forceUpdate()
    this.$router.go(0)
  },

  redirectToDetails(apInvoiceID) {
    if (this.isDebug == true) console.debug('redirectToDetails=' + apInvoiceID)
    if (apInvoiceID > 0) {
      const detailsPath = '/ap-invoice-detail/' + apInvoiceID + '?refreshed=true#tab'
      this.$router.push({
        path: detailsPath,
        params: { id: apInvoiceID }
      })
    }
  },

  async setToPosted() {
    if (this.isDebug == true) console.debug('...in setToPosted')

    if (
      !this.formData ||
      this.formData == undefined ||
      !this.apInvoiceID ||
      this.apInvoiceID <= 0
    ) {
      notifyError('There was a problem posting his invoice.')
      return
    }

    try {
      const path = `/AccountsPayable/APInvoice/PostToBill?apInvoiceID=${this.apInvoiceID}&description="Posted from Vue management portal."`

      this.loading = true
      const data = {
        apInvoiceID: this.apInvoiceID,
        description: 'Posted from Vue managment portal.'
      }

      const results = await kms.post(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results) {
        this.isReadyForPost = false
        this.reload()
        notifyMessage(`The invoice was successfully posted.`)
      }
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      if (e.message && e.message.indexOf('already has been assigned to a billing') !== -1) {
        notifyError(
          'This could not be posted. A line item has already been assigned to a bill distribution.'
        )
      } else {
        notifyError(e)
      }
    }
    this.loading = false
  },

  async setToApproved() {
    if (this.isDebug == true) console.debug('...in setToApproved')

    if (
      !this.formData ||
      this.formData == undefined ||
      !this.apInvoiceID ||
      this.apInvoiceID <= 0
    ) {
      notifyError('There was a problem setting your invoice to approved.')
      return
    }

    try {
      const payload = {
        apInvoiceID: this.apInvoiceID
      }

      this.$store.dispatch('accounting/approveInvoiceApproval', {
        payload: payload,
        done: ({ details }) => {
          if (details) {
            notifyMessage(details.approvalMessage)
            this.reload()
            this.isReadyForApproval = false
          }
        }
      })
    } catch (e) {
      this.loading = false
      if (e instanceof ValidationError) {
        notifyError(e)
      }

      notifyError(e)
    }
    this.loading = false
  },

  async setToRemoveStatus(fromStatus) {
    if (this.isDebug == true) console.debug('...in setToRemoveStatus')

    if (
      !this.formData ||
      this.formData == undefined ||
      !this.apInvoiceID ||
      this.apInvoiceID <= 0
    ) {
      notifyError('There was a problem removing the approval invoice status.')
      return
    }

    try {
      const data = {
        apInvoiceID: this.apInvoice.apInvoiceID,
        statusCode: 'Pending'
      }

      const path = `/AccountsPayable/APInvoiceApproval/SetApprovalStatus?apInvoiceID=${this.apInvoice.apInvoiceID}&statusCode=Pending`

      this.loading = true

      const results = await kms.post(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.apInvoiceApprovalID > 0) {
        this.reload()
        fromStatus == 'Approved'
          ? (this.isReadyForApproval = true)
          : (this.isReadyForApproval = false)
        this.isReadyForPost = false
        this.isDeniedStatus = false
        notifyMessage(`Successfully removed ${fromStatus} status from the invoice.`)
      }
    } catch (e) {
      this.loading = false
      if (e instanceof ValidationError) {
        notifyError(e)
      }

      notifyError(e)
    }
    this.loading = false
  },

  async deleteInvoice() {
    if (this.isDebug == true) console.debug('...in deleteInvoice')

    if (!this.apInvoiceID || this.apInvoiceID <= 0) {
      notifyError('There was a problem deleting your invoice.')
      return
    }

    try {
      const path = `/AccountsPayable/APInvoice/${this.apInvoiceID}?forceDelete=false`

      this.loading = true
      const data = { apInvoiceID: this.apInvoiceID, forceDelete: false }
      let results = await kms.delete(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.deleted == true) {
        this.returnToParent()
        notifyMessage(`The invoice was successfully deleted.`)
      }
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError('There was a problem deleting the invoice.' + e)
    }
    this.loading = false
  },

  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.payeeDropDownList) {
      this.data = this.payeeDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250)
}
