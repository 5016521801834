export function mounted() {
  this.current = 1
  if (this.$route.params.filtered) {
    if (this.isDebug == true) {
      console.debug('params=' + JSON.stringify(this.$route.params.filtered))
    }

    this.isForApproval = false

    const filtered = this.$route.params.filtered
    if (filtered == 'thirty') {
      this.reload()
    } else if (filtered == 'pending') {
      this.radio = 'pending'
    } else if (filtered == 'approval') {
      this.radio = 'approval'
      this.isForApproval = true
    } else if (filtered == 'post') {
      this.radio = 'post'
    }
  } else {
    this.reload()
  }
}
