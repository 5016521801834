export const watch = {
  hoaId() {
    this.reload('')
  },

  selectedRow(row) {
    // Go to unit details page when selected
    this.$router.push({ name: 'account.overview', params: { id: row.accountId } })
  },
  radio: 'reloadRequests',

  toggle() {
    if (this.toggle == false) {
      this.reload()
      this.toggle = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
    }
  },
  askQuestionToggle() {
    if (this.askQuestionToggle == false) {
      this.reload()
      this.askQuestionToggle = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
    }
  },
  denyWithNoteToggle() {
    if (this.denyWithNoteToggle == false) {
      this.reload()
      this.denyWithNoteToggle = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
    }
  }
}
