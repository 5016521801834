<template>
  <PageContentLayoutOnly>
    <div>
      <h3 style="display: inline-block">{{ $t('accountsPayable.title') }}</h3>
      <div class="pb-5 level">
        <div class="level-left">
          <b-button @click="createInvoiceModal()" size="is-small" type="is-primary" rounded>
            Create Invoice
          </b-button>
        </div>
        <div class="level-right">
          <b-radio v-model="radio" name="gridFilter" native-value="thirty">
            Last 30 Days
          </b-radio>
          <b-radio v-model="radio" name="gridFilter" native-value="pending">
            Pending Coding
          </b-radio>
          <b-radio v-model="radio" name="gridFilter" native-value="approval">
            Pending Approval
          </b-radio>
          <b-radio v-model="radio" name="gridFilter" native-value="post">
            Ready to Post
          </b-radio>
          <b-radio v-model="radio" name="gridFilter" native-value="query">
            Search
          </b-radio>
        </div>
      </div>

      <div
        v-if="isQuery && isQuery == true"
        class="card"
        :style="{
          padding: '20px',
          marginBottom: '20px',
          overflow: 'visible'
        }"
      >
        <div billing-period-range :style="{ paddingBottom: '12px' }">
          <h6 :style="{ margin: '0 0 6px' }">Billing Period Range</h6>
          <div
            :style="{
              display: 'flex',
              ...(breakpoint === 'mobile'
                ? {
                    flexDirection: 'column'
                  }
                : {
                    alignItems: 'center'
                  })
            }"
          >
            <DatePicker ref="fromDateComponent" ariaPrefix="from" />
            <span class="field" :style="{ padding: '0 12px' }">TO</span>
            <DatePicker ref="toDateComponent" ariaLabel="to date" ariaPrefix="to" />
            <div class="field" :style="{ padding: '0 12px' }">
              <b-button
                class="go-button"
                size="is-small"
                type="is-info"
                rounded
                @click="filterByDateRange"
                >Go</b-button
              >
            </div>
            <div></div>
          </div>
        </div>

        <div vendor-or-payee :style="{ paddingBottom: '12px' }">
          <h6 :style="{ margin: '0 0 6px' }">Vendor or Payee</h6>

          <div :style="{ display: 'flex' }">
            <valid-input
              name="Payee"
              maxlength="250"
              type="text"
              vid="payee"
              placeholder="Vendor or Payee"
              spellcheck="true"
              aria-label="Vendor or Payee"
              rules="max:250"
              v-model="payee"
            />
            <b-button
              class="float-right go-button"
              :style="{ marginLeft: '12px' }"
              size="is-small"
              type="is-info"
              rounded
              @click="filterByPayee"
              >Go</b-button
            >
          </div>
        </div>

        <div invoice-number :style="{ paddingBottom: '12px' }">
          <h6 :style="{ margin: '0 0 6px' }">Invoice Number</h6>

          <div :style="{ display: 'flex' }">
            <valid-input
              name="Payee"
              maxlength="250"
              type="text"
              vid="invoiceNumber"
              placeholder="Invoice Number"
              spellcheck="true"
              aria-label="Invoice Number"
              rules="max:250"
              v-model="invoiceNumber"
            />
            <b-button
              class="float-right go-button"
              :style="{ marginLeft: '12px' }"
              size="is-small"
              type="is-info"
              rounded
              @click="filterByInvoiceNumber"
              >Go</b-button
            >
          </div>
        </div>

        <div created-dates :style="{ paddingBottom: '12px' }">
          <h6 :style="{ margin: '0 0 6px' }">Created Dates</h6>
          <div
            :style="{
              display: 'flex',
              ...(breakpoint === 'mobile'
                ? {
                    flexDirection: 'column'
                  }
                : {
                    alignItems: 'center'
                  })
            }"
          >
            <DatePicker ref="fromCreatedDateComponent" ariaPrefix="from" />
            <span class="field" :style="{ padding: '0 12px' }">TO</span>
            <DatePicker ref="toCreatedDateComponent" ariaLabel="to date" ariaPrefix="to" />
            <div :style="{ marginBottom: '0.75rem' }">
              <b-button
                class="go-button field"
                :style="{ marginLeft: '12px' }"
                size="is-small"
                type="is-info"
                rounded
                @click="filterByCreatedDateRange"
                >Go</b-button
              >
            </div>
            <div></div>
          </div>
        </div>

        <div ap-amount :style="{ paddingBottom: '12px' }">
          <h6 :style="{ margin: '0 0 6px' }">Amount</h6>

          <div
            :style="{
              display: 'flex',
              ...(breakpoint === 'mobile'
                ? {
                    flexDirection: 'column'
                  }
                : {
                    alignItems: 'center'
                  })
            }"
          >
            <valid-input
              name="AmountFrom"
              maxlength="10"
              type="text"
              vid="amountFrom"
              id="amountFrom"
              placeholder="From"
              spellcheck="true"
              aria-label="From"
              rules="max:10"
              class="text-width-sm"
              :style="styles.amountField"
              v-model="amountFrom"
            />
            <span class="field" :style="{ marginTop: '8px', padding: '0 14px 19px 10px' }">TO</span>
            <valid-input
              name="AmountTo"
              maxlength="10"
              type="text"
              vid="amountTo"
              placeholder="To"
              spellcheck="true"
              aria-label="To"
              rules="max:10"
              class="text-width-sm"
              :style="styles.amountField"
              v-model="amountTo"
            />
            <div :style="{ padding: '0 0 25px 8px' }">
              <b-button
                class="go-button"
                size="is-small"
                type="is-info"
                rounded
                @click="filterByAmount"
                >Go</b-button
              >
            </div>
          </div>
        </div>

        <div class="pb-5">
          <span aria-label="Select G/L Expense">
            <label class="label">Select G/L Expense</label>
            <b-autocomplete
              size="is-small"
              field="label"
              icon="magnify"
              :loading="isFetching"
              placeholder="e.g. G/L Expense"
              :data="data"
              :open-on-focus="openOnFocus"
              v-model="glExpenseFilterQuery"
              required="true"
              @typing="getAsyncData"
              @select="option => (selected = option)"
            />
          </span>

          <b-button
            :style="{ margin: '10px' }"
            class="go-button"
            size="is-small"
            type="is-info"
            rounded
            @click="filterByGl"
            >Go</b-button
          >
        </div>
      </div>
    </div>

    <div v-if="apInvoices && apInvoices != undefined">
      <div style="padding-bottom:30px;" v-for="(c, index) in apInvoices" :key="index">
        <div class="columns box is-12" :style="{ marginLeft: 0, marginRight: 0 }">
          <div class="column is-3">
            <p class="font-bold">{{ c.vendor ? 'Payee (Vendor)' : 'Payee (Owner)' }}</p>
            <p class="font-normal">{{ c.payee || 'None' }}</p>

            <p class="font-bold pt-3">Date Created</p>
            <p class="font-normal">{{ c.createdDate || '' }}</p>

            <p class="font-bold pt-3">Invoice Number</p>
            <p class="font-normal">{{ c.invoiceNumber || '' }}</p>
          </div>
          <div class="column is-3 font-bold">
            <p class="font-bold">Status</p>
            <p class="font-normal">{{ c.status || '' }}</p>

            <p class="font-bold pt-3">Invoice Total</p>
            <p class="font-normal">{{ c.invoiceTotal || '' }}</p>
          </div>
          <div class="column is-2 font-bold">
            <p class="font-bold">Eligible Approvers</p>
            <div v-if="c.approvers">
              <div
                v-for="(a, apInvoiceApprovalApproverID) in c.approvers"
                :key="apInvoiceApprovalApproverID"
              >
                <p
                  v-if="a.accountManagingUserName && a.accountManagingUserName.length > 0"
                  class="font-normal"
                >
                  {{
                    `${a.accountManagingUserName} - ${a.approvalDate ? 'Approved' : 'Not Approved'}`
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <p>
              <b-button
                class="button is-info is-light is-outline is-rounded is-small"
                :style="{ marginRight: '8px', marginBottom: '8px' }"
                @click.prevent="redirectToDetails(c.apInvoiceID)"
                >Details</b-button
              >
              <b-button
                class="button is-info is-light is-outline is-rounded is-small"
                :style="{ marginRight: '8px', marginBottom: '8px' }"
                @click.prevent="downloadPdf(c.apInvoiceID)"
                >View Invoice
              </b-button>
              <b-button
                @click.prevent="redirectToAddNote(c.apInvoiceID)"
                class="button is-info is-light is-outline is-rounded is-small"
                :style="{ marginRight: '8px', marginBottom: '8px' }"
                >Add Note</b-button
              >
              <b-button
                @click.prevent="openAskQuestionModal(c.apInvoiceID, c.payee)"
                class="button is-info is-light is-outline is-rounded is-small"
                :style="{ marginRight: '8px', marginBottom: '8px' }"
                >Ask a Question</b-button
              >
            </p>
            <p class="pt=5" style="padding-top: 30px;">
              <button
                :disabled="c.approveTooltip && c.approveTooltip.length > 0 ? true : false"
                @click.prevent="redirectToSetApproved(c.apInvoiceID)"
              >
                <StatusTile
                  :title="c.approveTooltip"
                  text="Approve"
                  :background="c.approveTooltip && c.approveTooltip.length > 0 ? 4 : 2"
                />
              </button>
              <span class="pl-2">&nbsp;</span>
              <button
                :disabled="c.approveTooltip && c.approveTooltip.length > 0 ? true : false"
                @click.prevent="confirmSetDenied(c)"
              >
                <StatusTile
                  :title="c.approveTooltip"
                  text="Deny"
                  :background="c.approveTooltip && c.approveTooltip.length > 0 ? 4 : 3"
                />
              </button>
              <span class="pl-2">&nbsp;</span>
            </p>
          </div>
        </div>
      </div>
      <div :style="styles.paginationContainer" class="table-a-pagination-container">
        <b-pagination
          :total="total"
          v-model="current"
          @change="paginationChange"
          :range-before="rangeBefore"
          :range-after="rangeAfter"
          :order="order"
          :size="size"
          :simple="isSimple"
          :rounded="isRounded"
          :per-page="perPage"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
        </b-pagination>
      </div>
    </div>

    <Modal :toggle.sync="toggle">
      <template v-slot:header>Create Invoice</template>
      <InvoiceCreator :toggle="toggle" />
    </Modal>

    <Modal :toggle.sync="askQuestionToggle">
      <AskQuestionModal
        :apInvoiceID="parseInt(apInvoiceID)"
        :apInvoicePayeeID="parseInt(apInvoicePayeeID)"
        :apInvoiceLabel="apInvoiceLabel"
      />
    </Modal>

    <Modal :toggle.sync="denyWithNoteToggle">
      <DenyWithNoteModal :apInvoiceID="parseInt(apInvoiceID)" :apInvoice="apInvoice" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import StatusTile from '@/components/tiles/Status'
import DatePicker from '@/components/inputs/DatePicker'
import ValidInput from '@/components/inputs/ValidInput'
import Modal from '@/components/Modal'
import InvoiceCreator from '@/components/InvoiceCreator'
import AskQuestionModal from '@/pages/Shared/communications/Global/components/MessageModal'
import { watch } from './keys/watch'
import { mounted } from './keys/mounted'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { computed } from './keys/computed'
import DenyWithNoteModal from './components/DenyWithNoteModal/index.vue'

export default {
  name: 'AccountsPayable',
  components: {
    PageContentLayoutOnly,
    DatePicker,
    ValidInput,
    Modal,
    InvoiceCreator,
    AskQuestionModal,
    DenyWithNoteModal,
    StatusTile
  },

  computed,
  data,
  methods,
  mounted,
  watch,

  i18n: {
    messages: {
      en: { accountsPayable: { title: 'Accounts Payable' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}

.table-a-pagination-container {
  .pagination {
    .pagination-list {
      order: 1 !important;
    }
    .pagination-previous {
      order: 2 !important;
    }
    .pagination-next {
      order: 3 !important;
    }
  }
}
</style>
