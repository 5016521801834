var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  isQuery: false,
  payee: null,
  invoiceNumber: null,
  amountFrom: 0,
  amountTo: 0,
  radio: 'thirty',
  loading: false,
  selectedRow: null,
  openOnFocus: true,
  glExpenseFilterQuery: null,
  askQuestionToggle: false,
  denyWithNoteToggle: false,
  isFetching: false,
  data: [],
  apInvoice: null,
  apInvoiceID: 0,
  apInvoiceLabel: '',
  apInvoicePayeeID: 0,
  apInvoices: [],
  originalApInvoices: [],
  apStatuses: [],
  payeeLookup: [],
  total: 0,
  current: 0,
  perPage: 5,
  rangeBefore: 3,
  rangeAfter: 1,
  order: 'is-right',
  size: '',
  isSimple: false,
  isRounded: false,
  hasInput: false,
  prevIcon: 'chevron-left',
  nextIcon: 'chevron-right',

  filters: {
    show: true
  },

  pagination: {
    perPage: 10
  },

  columns: [
    {
      field: 'invoiceNumber',
      label: 'Invoice #',
      aria: 'Invoice #',
      width: '5%',
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'payee',
      label: 'Payee',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'payeeInformation',
      label: 'Payee Info',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      date: true,
      width: '12%',
      sortable: true,
      searchable: true
    },
    {
      field: 'financialPeriod',
      label: 'Fin. Period',
      date: true,
      width: '12%',
      sortable: true,
      searchable: true
    },
    {
      field: 'status',
      label: 'Status',
      width: '10%',
      sortable: true,
      searchable: [
        {
          label: 'All',
          filter: ''
        },
        {
          label: 'Approved',
          filter: 'Approved'
        },
        {
          label: 'Pending',
          filter: 'Pending'
        },
        {
          label: 'Denied',
          filter: 'Denied'
        },
        {
          label: 'Paid',
          filter: 'Paid'
        },
        {
          label: 'Unpaid',
          filter: 'Unpaid'
        }
      ]
    },
    {
      field: 'invoiceTotal',
      label: 'Total',
      width: '10%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
