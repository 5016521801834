import { mapState, mapGetters } from 'vuex'

export const computed = {
  ...mapGetters('accounting', ['apInvoiceStatusList']),
  ...mapGetters('accounting', ['accountsPayableApInvoiceList']),
  ...mapGetters('accounting', ['glExpenseDropDownList']),
  ...mapGetters('accounting', ['apInvoiceStatus']),
  glExpenseFilterSuggestions() {
    if (
      this.glExpenseFilterQuery &&
      this.glExpenseDropDownList &&
      this.glExpenseDropDownList != undefined
    ) {
      return this.glExpenseDropDownList.filter(
        a => a.label.indexOf(this.glExpenseFilterQuery.toLowerCase()) !== -1
      )
    } else {
      return this.glExpenseDropDownList
    }
  },
  ...mapState({
    breakpoint: state => state.win.breakpoint,
    hoaId: state => state.user.selectedHoaId,
    payeeFilterSuggestions() {
      if (this.payeeFilterQuery && this.payeeDropDownList && this.payeeDropDownList != undefined) {
        return this.payeeDropDownList.dropDownPayees.filter(
          a => a.label.indexOf(this.payeeFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.payeeDropDownList.dropDownPayees
      }
    }
  })
}
