export function data() {
  return {
    formData: {
      note: ''
    },
    isDebug: true,
    newId: null,
    isOpen: false,
    loading: true,
    openOnFocus: true,
    showWeekNumber: false,
    buttonText: 'Add'
  }
}
