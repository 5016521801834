import { accountsPayable } from '@/services/Invoices/store'
import $ from 'jquery'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    this.loading = true

    this.buttonText = 'Add'

    this.loading = false
  },

  open() {
    this.formData = {
      note: ''
    }
    this.isOpen = true
    this.loading = false
  },

  async submitNote() {
    if (this.formData && this.formData != undefined) {
      await this.addDenyWithNote()
    }
  },

  async addDenyWithNote() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this deny with note submission.')
      return
    }

    this.loading = true

    if (this.isDebug == true)
      console.debug('addDenyWithNote params=' + JSON.stringify(this.formData))

    await accountsPayable.dispatch('addDenyWithNote', {
      note: _get(this.formData, 'note', ''),
      done: ({ details }) => {
        this.loading = false
        if (details && details != undefined) {
          this.leaveForm()
        }
      }
    })

    this.loading = false
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  }
}
