<template>
  <PageContentLayoutOnly id="accounts-payable-invoice-detail">
    <div v-if="formData">
      <div class="box is-12">
        <div class="float-right">
          <div class="float-left">
            <svg
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
          </div>
          <div class="float-right">
            <b-button label="Back to Accounts Payable" @click.prevent="returnToParent()" />
          </div>
        </div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div
                  class="font-bold"
                  v-if="
                    apInvoice &&
                      apInvoice != undefined &&
                      apInvoice.apInvoiceApproval &&
                      apInvoice.apInvoiceApproval != undefined &&
                      apInvoice.apInvoiceApproval.apInvoiceStatus &&
                      apInvoice.apInvoiceApproval.apInvoiceStatus != undefined
                  "
                >
                  <span>{{ `Invoice ID ${formData.apInvoiceID} - ` }}</span>
                  <span v-if="apInvoice.apInvoiceApproval.approvalDate && isPostedAlready">
                    {{ `Posted:  ${formatDateFn(apInvoice.apInvoiceApproval.approvalDate)}` }}
                  </span>
                  <span v-else-if="apInvoice.apInvoiceApproval.approvalDate">
                    {{
                      `${apInvoice.apInvoiceApproval.apInvoiceStatus.status}:  ${formatDateFn(
                        apInvoice.apInvoiceApproval.approvalDate
                      )}`
                    }} </span
                  ><span v-else>
                    {{ apInvoice.apInvoiceApproval.apInvoiceStatus.status }}
                  </span>
                </div>
                <div v-else class="font-bold">No Status</div>
              </fieldset>
              <fieldset>
                <div class="columns">
                  <div v-show="false" class="column is-6">
                    <valid-input
                      aria-readonly="true"
                      aria-disabled="true"
                      disabled="true"
                      style="width:50px !important;"
                      name="ApInvoiceID"
                      label="Invoice ID"
                      type="text"
                      vid="ApInvoiceID"
                      placeholder="Invoice ID"
                      spellcheck="true"
                      aria-label="Invoice ID"
                      rules="required"
                      class="is-small pendingcolor"
                      v-model="formData.apInvoiceID"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="InvoiceNumber"
                      label="Invoice Number"
                      maxlength="50"
                      type="text"
                      vid="InvoiceNumber"
                      placeholder="Invoice Number"
                      spellcheck="true"
                      aria-label="Incoice Number"
                      rules="max:50|required"
                      class="is-small"
                      v-model="formData.invoiceNumber"
                    />
                  </div>
                  <div v-show="false" class="column is-6">
                    <b-field label="Posting Date">
                      <b-datepicker
                        v-model="formData.postingDate"
                        label="Posting Date"
                        rules="required"
                        aria-required="Posting Date is required"
                        :show-week-number="showWeekNumber"
                        placeholder="Click to select Posting Date..."
                        icon="calendar-today"
                        trap-focus
                      ></b-datepicker>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <valid-input
                      disabled="true"
                      :readonly="true"
                      style="width:50px !important;"
                      name="CreatedDate"
                      label="Created Date"
                      maxlength="50"
                      type="text"
                      vid="CreatedDate"
                      placeholder="Created Date"
                      spellcheck="true"
                      aria-label="Created Date"
                      class="is-small"
                      v-model="formData.createdDate"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6" aria-label="Select Payee">
                    <label class="label">Payee</label>
                    <b-autocomplete
                      size="is-small"
                      field="label"
                      icon="magnify"
                      :loading="isFetching"
                      placeholder="e.g. Vendor Name or Owner Name"
                      :data="data"
                      :open-on-focus="openOnFocus"
                      v-model="payeeFilterQuery"
                      required="true"
                      @typing="getAsyncData"
                      @select="option => (selected = option)"
                    >
                    </b-autocomplete>
                  </div>
                  <div class="column is-6">
                    <valid-input
                      disabled="true"
                      :readonly="true"
                      style="width:50px !important;"
                      name="CodingCompletedDate"
                      label="Coding Completed Date"
                      maxlength="50"
                      type="text"
                      vid="CodingCompletedDate"
                      placeholder="Coding Completed Date"
                      spellcheck="true"
                      aria-label="Coding Completed Date"
                      class="is-small"
                      v-model="formData.codingCompletedDate"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div v-show="false" class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="PayeeInformation"
                      :label="isVendor == true ? `Vendor Information` : `Owner Information`"
                      maxlength="100"
                      type="text"
                      vid="PayeeInformation"
                      :placeholder="isVendor == true ? `Vendor Information` : `Owner Information`"
                      spellcheck="true"
                      aria-label="Payee Information"
                      rules="max:100|required"
                      class="is-small"
                      v-model="formData.payeeInformation"
                    />
                  </div>
                  <div v-show="false" class="column is-6">
                    <b-field label="Financial Period">
                      <DatePicker ref="financialDateComponent" />
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6" :style="{ padding: 0 }">
                    <b-field
                      style="min-width: 222px;"
                      class="column"
                      label="Billing Period Start Date"
                    >
                      <b-datepicker
                        v-model="formData.startDate"
                        label="Billing Period Start Date"
                        rules="required"
                        aria-required="Billing Period Start Date is required"
                        :show-week-number="showWeekNumber"
                        placeholder="Click to select Billing Period Start Date..."
                        icon="calendar-today"
                        trap-focus
                      ></b-datepicker>
                    </b-field>
                    <b-field
                      style="min-width: 222px !important;"
                      class="column"
                      label="Billing Period End Date"
                    >
                      <b-datepicker
                        v-model="formData.endDate"
                        label="Billing Period End Date"
                        rules="required"
                        aria-required="Billing Period End Date is required"
                        :show-week-number="showWeekNumber"
                        placeholder="Click to select Billing Period End Date..."
                        icon="calendar-today"
                        trap-focus
                      ></b-datepicker>
                    </b-field>
                  </div>

                  <div class="column is-4">
                    <valid-input
                      style="width:50px !important;"
                      aria-readonly="true"
                      aria-disabled="true"
                      disabled="true"
                      name="InvoiceDocument"
                      label="Invoice Document"
                      type="text"
                      vid="InvoiceDocument"
                      placeholder=""
                      spellcheck="true"
                      aria-label="Incoice Number"
                      class="is-small"
                      v-model="formData.invoiceDocument"
                    />
                    <b-button type="is-text" @click.prevent="setToggleUpload()"
                      >View or Upload</b-button
                    >
                  </div>
                </div>
                <div class="column is-12">
                  <button
                    v-if="isReadyForApproval && apInvoice && apInvoice.invoiceNumber"
                    @click.prevent="confirmSetApproved(apInvoice.invoiceNumber)"
                    type="button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    Set Approved
                  </button>
                  <span
                    v-if="isReadyForApproval && apInvoice && apInvoice.invoiceNumber"
                    class="p-2"
                  >
                  </span>
                  <button
                    @click.prevent="confirmPostToBill(apInvoice.invoiceNumber)"
                    v-if="isReadyForPost"
                    type="button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    Post
                  </button>
                  <button
                    @click.prevent="confirmSetRemoveDenied(apInvoice.invoiceNumber)"
                    v-if="isDeniedStatus && apInvoice && apInvoice.invoiceNumber"
                    type="button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    Reset Denied
                  </button>
                  <span v-if="isDeniedStatus" class="p-2"> </span>
                  <span v-if="isReadyForPost" class="p-2"> </span>
                  <button
                    @click.prevent="confirmSetRemoveApproved(apInvoice.invoiceNumber)"
                    v-if="isReadyForPost && apInvoice && apInvoice.invoiceNumber"
                    type="button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    Reset Approval
                  </button>
                  <span v-if="isReadyForPost" class="p-2"> </span>
                  <button
                    v-if="!isPostedAlready"
                    type="submit"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    Update
                  </button>
                  <span class="p-2"></span>
                  <button
                    v-if="!isPostedAlready"
                    type="button"
                    @click.prevent="confirmDeleteInvoice(formData.invoiceNumber)"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    Delete
                  </button>
                </div>
                <div class="pb-6"></div>
                <div class="pb-6"></div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
    <Modal :toggle.sync="toggle">
      <template v-slot:header>Invoice Document</template>

      <DocModal :apInvoiceID="parseInt(apInvoiceID)" :reloadPhotos="reloadPhotos" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
//
import DocModal from '@/components/DocumentInvoiceModal'
import Modal from '@/components/Modal'
import DatePicker from '@/components/inputs/DateMonthYearPicker'
import ValidInput from '@/components/inputs/ValidInput'
//
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
//
import { data } from './keys/data'
import { methods } from './keys/methods'
//
export default {
  name: 'InvoiceDetail',
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly,
    DocModal,
    Modal,
    DatePicker
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    }),
    ...mapGetters('accounting', ['payeeDropDownList']),
    ...mapGetters('accounting', ['apInvoice']),
    ...mapGetters('accounting', ['apInvoiceStatus']),
    payeeFilterSuggestions() {
      if (this.payeeFilterQuery && this.payeeDropDownList && this.payeeDropDownList != undefined) {
        return this.payeeDropDownList.filter(
          a => a.label.indexOf(this.payeeFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.payeeDropDownList
      }
    }
  },

  mounted() {
    this.loading = true
    if (this.$route.params.id) {
      this.apInvoiceID = this.$route.params.id
    }

    this.$store.dispatch('accounting/getPayeeList').then(() => {
      if (this.payeeDropDownList && this.payeeDropDownList != undefined) {
        if (this.isDebug == true) {
          console.debug('payeeDropDownList=' + JSON.stringify(this.payeeDropDownList))
        }

        this.data = this.payeeDropDownList
      }
    })

    if (this.apInvoiceID) {
      if (this.isDebug == true) console.debug('apInvoiceId=' + this.apInvoiceID)
      this.reload()
    }

    if (
      this.$route.params.setapproved &&
      this.apInvoice &&
      this.apInvoice.invoiceNumber &&
      this.apInvoice.invoiceNumber != undefined
    ) {
      this.confirmSetApproved(this.apInvoice.invoiceNumber)
    }

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    invoiceAttachmentToggle() {
      if (this.invoiceAttachmentToggle == false) {
        if (this.isDebug == true) console.debug('...in invoiceAttachmentToggle()')
        this.invoiceAttachmentToggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
        this.reload()
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        invoiceDetail: { title: 'Invoice Detail' },
        invoiceCharges: { title: 'Charges' },
        invoiceNotes: { title: 'Notes' },
        invoiceAttachments: { title: 'Attachments' },
        invoiceApprovals: { title: 'Approvals' }
      }
    }
  }
}
</script>

<style lang="scss">
#accounts-payable-invoice-detail {
  small {
    height: 0px !important;
  }

  .step-details {
    background-color: #fff !important;
    padding-left: 0.25rem !important;
    padding-right: 0.5rem !important;
  }

  .b-steps.is-small .steps {
    margin-top: 1rem;
  }

  .b-steps .steps + .step-content {
    padding-top: 0 !important;
  }

  .step-navigation {
    display: flex;
    justify-content: flex-end;
  }

  .my-grid-class {
    height: 400px;
  }

  tbody {
    min-height: 100px !important;
    overflow: auto !important;
  }
}
</style>
